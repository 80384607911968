<template>
    <div class="clearfix hide-print" style="padding:10px 20px;">
        <div class="btn-toolbar pull-right">
            <div class="btn-group" v-if="filter.month">
                <button id="year-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> <span id="time-dropdown-btn-text">{{filter.month.name}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="year-dropdown" v-if="arrayMonth">
                    <li v-for="month in arrayMonth" :key="month.name"><a @click.prevent="filterMonthChanged(month)" href = "">{{month.name}}</a></li>
                </ul>
            </div>
            <div class="btn-group" v-if="filter.year">
                <button id="time-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> <span id="year-dropdown-btn-text">{{filter.year}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="time-dropdown">
                    <li v-for="year in arrayYear" :key="year"><a @click.prevent="filterYearChanged(year)" href = "">{{year}}</a></li>
                </ul>
            </div>
            <a class="btn btn-default btn-sm hide-print" href="javascript:;" onclick="window.print();">
                <span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa
            </a>
        </div>
    </div>
</template>

<script>
	import baseMixin from '@/common/baseMixin'
    import moment from 'moment'
    import {MONTH_LIST} from "@/common/constants";
    import {Utils} from "@/common/utils";

	export default {
		
		name: "Statement",
		mixins: [baseMixin],
		props: {
            defaultYear: {
                type: Number,
                default: moment().year()
            },
            defaultMonth: {
                type: Number,
                default: moment().month(),
            }
		},
		mounted () {
            this.creatorsOptions = this.Keys.CREATORS_OPTIONS();
            this.filter = {
                month: Utils.getMonthObjByValue(this.defaultMonth),
                year: this.defaultYear
            }
		},
		data: () => {
			return {
                creatorsOptions: [],
                filter: {}
			}
		},
        computed: {
			arrayMonth: function () {
				let sourceMonth = MONTH_LIST
				let monthList = []
				if (this.filter.year === moment().year()) {
					for (let i = moment().month(); i >= 0; i--) {
						monthList.push(sourceMonth[i])
					}
					return monthList
				} else {
					for (let i = sourceMonth.length - 1; i >= 0; i--) {
						monthList.push(sourceMonth[i])
					}
					return monthList
				}
			},
            arrayYear: function () {
                let startYear = 2020;
                let arrayYear = [];

                for (let i = this.defaultYear; i >= startYear; i--) {
                    arrayYear.push(i)
                }
                return arrayYear;
            }
        },
		methods: {
            filterMonthChanged(month=null) {
                this.filter.month = month;
                this.$emit('filter-changed', this.filter)
            },
            filterYearChanged(year=null) {
				this.filter.year = year;
                this.$emit('filter-changed', this.filter)
            },
		}
	}
</script>
