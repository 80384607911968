<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row">
                <div class="col-xs-6">
                    <h1 class="h2 margin-bottom-20">Estratto Conto</h1>
                </div>
                <StatementFilters @filter-changed="filterChanged" :default-month = "monthFilter" :default-year="yearFilter"/>

            </div>
            <div>
                <div class="panel panel-default">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td>Biglietti venduti c/o biglietterie</td>
                            <td>Quantità
                            </td><td class="col-xs-3 text-right">{{ statementObj.count_total }}</td>
                            <td class="col-xs-3 text-right"></td>
                        </tr>
                        <tr class="active">
                            <td></td>
                            <td>Importo lordo
                            </td><td class="col-xs-3 text-right"></td>
                            <td class="col-xs-3 text-right">€{{ parseFloat(statementObj.price_final_total).toFixed(2) }}</td>
                        </tr>
                        <tr class="active">
                            <td></td>
                            <td>Costi del servizio (5%)
                            </td><td class="col-xs-3 text-right"></td>
                            <td class="col-xs-3 text-right">-€{{ (parseFloat(statementObj.price_fee_total)).toFixed(2) }}</td>
                        </tr>
                        <tr class="active">
                            <td></td>
                            <td>IVA (Split payment)
                            </td><td class="col-xs-3 text-right"></td>
                            <td class="col-xs-3 text-right">-€{{ parseFloat(statementObj.price_fee_vat_total).toFixed(2) }}</td>
                        </tr>
<!--                        <tr>-->
<!--                            <td>Biglietti venduti online</td>-->
<!--                            <td>Quantità-->
<!--                            </td><td class="col-xs-3 text-right">65</td>-->
<!--                            <td class="col-xs-3 text-right"></td>-->
<!--                        </tr>-->
<!--                        <tr class="active">-->
<!--                            <td></td>-->
<!--                            <td>Importo lordo-->
<!--                            </td><td class="col-xs-3 text-right"></td>-->
<!--                            <td class="col-xs-3 text-right">€260.00</td>-->
<!--                        </tr>-->
<!--                        <tr class="active">-->
<!--                            <td></td>-->
<!--                            <td>Costi del servizio (5%)-->
<!--                            </td><td class="col-xs-3 text-right"></td>-->
<!--                            <td class="col-xs-3 text-right">-€13.00</td>-->
<!--                        </tr>-->
<!--                        <tr class="active">-->
<!--                            <td></td>-->
<!--                            <td>IVA (Split payment)-->
<!--                            </td><td class="col-xs-3 text-right"></td>-->
<!--                            <td class="col-xs-3 text-right">-€2.86</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <td>Bonifici ricevuti</td>-->
<!--                            <td>Quantità-->
<!--                            </td><td class="col-xs-3 text-right">4</td>-->
<!--                            <td class="col-xs-3 text-right"></td>-->
<!--                        </tr>-->
<!--                        <tr class="active">-->
<!--                            <td></td>-->
<!--                            <td>Importo lordo-->
<!--                            </td><td class="col-xs-3 text-right"></td>-->
<!--                            <td class="col-xs-3 text-right">€257.14</td>-->
<!--                        </tr>-->
                        <tr>
                            <td colspan="3" class="bold">Da pagare</td>
                            <td class="col-xs-3 text-right bold">€{{ (parseFloat(statementObj.price_fee_total)).toFixed(2) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
	import baseMixin from '@/common/baseMixin'
    import StatementFilters from "@/components/Statement/StatementFilters";
    import moment from 'moment'
    import {getStatement} from "@/api";


    export default {
        title: "Estratto Conto",
        name: "Statement",
		mixins: [baseMixin],

        components: {ContainerWithMenu, StatementFilters},
		data: function() {
            return {
                loading: false,
                monthFilter: moment().month(),
                yearFilter: moment().year(),
                statementObj: {}
            }
        },
        mounted() {
            this.loadStatementData(this.monthFilter, this.yearFilter)
        },
        methods: {
            async loadStatementData(month, year) {
                this.loading = true;
                try {
                    this.statementObj = await getStatement(month, year);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            filterChanged(filter){
                this.loadStatementData(filter.month.value, filter.year)
            }
        }
    };
</script>
